<template>
  <div class="form-section" v-if="amReady">
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible" custom-class="" title="" top="3vh" width="1228px">
      <report-print-form v-if="dialogVisible" :title="reportTitle" @close="dialogVisible = false" :subTitle="dueDateLabel" :reportColumns="reportColumns" :reportData="reportData" :reportCellCustomClass="reportCellCustomClass"></report-print-form>
    </el-dialog>

    <el-row>
      <el-col style="width: fit-content">
        <div class="title-2">{{reportUiName}}</div>
      </el-col>
      <el-col :class="$langConfig.cssClass" style="width: 220px;margin-left: 10px;">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="14"
            :label_span="10"
            valueProperty="value"
            :options="[...$store.state['App-User-api'].usersByProfiles.BOOKKEEPER, {'label': 'All', 'value': '-all-'}]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark="required"
            label="BookKeeper"
            name="account_bookkeeper_user_id"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" style="width: 120px;margin-left: 10px;">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="13"
            :label_span="11"
            :options="dropdown12Month"
            :translateLabel='false'
            :translateLabels="false"
            field_mark="required"
            label="Month"
            name="month"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-button @click="report" size="small" style="position: absolute;right: 10px;" type="primary">Report</el-button>
    </el-row>
  </div>
</template>

<script>
import TaxFilingTypeJson from "@/json/dropdown/TaxFilingType.json";
import jsonTaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";
import json12month from "@/json/dropdown/12Month.json";
import jsonAccountReportingPeriods from "@/json/dropdown/AccountingReportingPeriods.json";
import reportingMethodJson from "@/json/dropdown/ReportingMethod.json";

export default {
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
  props: {
    province_of_operation_id: {
      required: true
    }
  },
  components: {
    'report-print-form': () => import('@/modules/Accounting/Report/report-print-form/View')
  },
  methods: {
    getObjectFromValue(v) {
      let op = $store.state['App-User-api'].usersByProfiles.BOOKKEEPER;
      for (let x in op) {
       if (op[x].value == v) return op[x]
      }
      return null
    },
    ...$mapActions('App-User-api', {
      getUsersByProfileDropdownList: 'getUsersByProfileDropdownList',
    }),
    getFormData() {
      let promises = []
      promises.push(this.getUsersByProfileDropdownList({profileId: 'BOOKKEEPER'}))
      Promise.all(promises).then((values) => {
        this.amReady = true
      })
    },
    reportCellCustomClass(cell, data) {
      if (cell.prop == 'business_name') {
        if (data.tax_hst_priority_id == '2' && this.withHighPriority) {
          return 'bg-warning'
        }
      }

      return '';
    },
    async report() {
      this.requestErrorMessage = null
      let isValid = await this.validateFields().then(result => result).finally(() => {
        this.requestOn = false
      })
      if (!isValid) return

      let currentYear = new Date().getFullYear();
      let currentMonthName = new Date(currentYear, this.dataModel.month, 1).toLocaleString('default', {month: 'short'});
      this.dueDateLabel = "Month of: " + currentMonthName + ", " + currentYear
      this.accountingTypeLabel = this.getAccountingTypeFromId(this.dataModel.reporting_type)

      let reportOption = this.dataModel.account_bookkeeper_user_id;

      if (reportOption == '-all-') {
        this.reportTitle = "BOOKKEEPER FOR ALL"
        this.reportColumns = [...this.reportOriginal, ...$services['Accounting-Report'].getReportColumns(['account_bookkeeper_user_name'])  ]
      } else {
        this.reportColumns = [...this.reportOriginal]
        this.reportTitle = "BOOKKEEPER: " + (this.getObjectFromValue(reportOption).label).toString().toUpperCase()
      }


      let api = {
        report: this.reportApi,
        data: this.dataModel
      }

      $store.dispatch('Accounting-Report-api/controlReports', api).then(r => {
        this.reportData = r
        this.openDataInDialogToPrint()
      })
    },
    openDataInDialogToPrint() {
      this.dialogVisible = true
    },
    getAccountingTypeFromId(id) {
      let o = this.dropdownAccountingOptions;
      let l = o.length
      for (let i = 0; i < l; i = i + 1) if (o[i].value == id) return o[i].label
      return ''
    },
    getGstHstTvqReportingType(id) {
      let o = this.dropdownAccountingOptions;
      let l = o.length
      for (let i = 0; i < l; i = i + 1) if (o[i].value == id) return o[i].label
      return ''
    },
  },
  beforeMount() {
    this.dataModel['province_of_operation_id'] = this.province_of_operation_id
    this.reportOriginal = [
      ...$services['Accounting-Report'].getReportColumns(['business_name', 'business_number', 'contact_name', 'full_work_phone', 'comment', 'fees_paid', 'done'])
    ]

    this.getFormData()
  },
  data() {
    return {
      amReady: false,
      reportDateType: '',
      reportApi: 'controlReportBookkeeper',
      reportUiName: 'BOOKKEEPER: ',
      reportTitle: "BOOKKEEPER FOR ",
      dropdownReportingMethod: reportingMethodJson,
      withHighPriority: false,
      accountingTypeLabel: '',
      dropdownAccountingOptions: jsonAccountReportingPeriods,
      dueDateLabel: '',
      printPageWidth: 3508,
      printPageMargin: 10,
      reportData: [],
      reportColumns: [],
      reportOriginal: [],
      dialogVisible: false,
      control: this,
      TaxFilingTypeDropdown: TaxFilingTypeJson,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
      dropdown12Month: json12month,
      taxReportingType: null,
    }
  }
}
</script>

<style scoped>
.table-for-printing tr:nth-child(even) td div {
  -webkit-print-color-adjust: exact;
  background-color: #c8c8c8;
}

.table-for-printing tbody:not(:nth-child(2)) tr:first-child {
  height: 50px;
  vertical-align: bottom
}

.table-for-printing tr:nth-child(odd) td div {
  border: 2px solid #000 !important;
  border-radius: 8px;
}

.table-for-printing-with-high-priority tr:nth-child(even) td div {
  -webkit-print-color-adjust: exact;
  background-color: #f1d03b;
  color: #000;
}

.table-for-printing-with-high-priority tr:nth-child(odd) td div {
  border: 2px solid #f1d03b !important;
  border-radius: 8px;
}
</style>
