<template>
  <div class="form-section" v-if="amReady">
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible" custom-class="" title="" top="3vh" width="1228px">
        <report-print-form v-if="dialogVisible" :title="reportTitle" @close="dialogVisible = false" :subTitle="dueDateLabel" :reportColumns="reportColumns" :reportData="reportData" :reportCellCustomClass="reportCellCustomClass"></report-print-form>
    </el-dialog>

    <el-row>
      <el-col style="width: fit-content">
        <div class="title-2">{{reportUiName}}</div>
      </el-col>
      <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 10px;">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :editMode="editMode"
            :field_span="15"
            :label_span="9"
            :options="dropdownAccountStatus"
            :translateLabel='false'
            :translateLabels="false"
            @amUpdated="(p, val) =>  { taxReportingType = val}"
            field_mark="required"
            label="Option"
            name="report_option"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-button @click="report" size="small" style="position: absolute;right: 10px;" type="primary">Report</el-button>
    </el-row>
  </div>
</template>

<script>
import TaxFilingTypeJson from "@/json/dropdown/TaxFilingType.json";
import jsonTaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";
import json12month from "@/json/dropdown/12Month.json";
import jsonAccountReportingPeriods from "@/json/dropdown/AccountingReportingPeriods.json";
import reportingMethodJson from "@/json/dropdown/ReportingMethod.json";
import jsonAccountStatus from "@/json/dropdown/AccountStatus.json";

export default {
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
  props: {
    province_of_operation_id: {
      required: true
    }
  },
  components: {
    'report-print-form': () => import('@/modules/Accounting/Report/report-print-form/View')
  },
  methods: {
    reportCellCustomClass(cell, data) {
      if (cell.prop == 'business_name') {
        if (data.tax_hst_priority_id == '2' && this.withHighPriority) {
          return 'bg-warning'
        }
      }

      return '';
    },
    async report() {
      this.requestErrorMessage = null
      let isValid = await this.validateFields().then(result => result).finally(() => {
        this.requestOn = false
      })
      if (!isValid) return

      let currentYear = new Date().getFullYear();
      let lastDayOfMonth = new Date(currentYear, this.dataModel.period_due + 2, 0).getDate();
      let nextMonthName = new Date(currentYear, this.dataModel.period_due + 1, 1).toLocaleString('default', {month: 'short'});
      if (this.reportDateType == 'currentDay') {
        let d = new Date()
        this.dueDateLabel = '' + d.toLocaleString('default', {month: 'short'}) + " " + d.getDate() + ", " + d.getFullYear()
      } else {
        this.dueDateLabel = "" + nextMonthName + " " + lastDayOfMonth + ", " + currentYear
      }

      this.accountingTypeLabel = this.getAccountingTypeFromId(this.dataModel.reporting_type)

      let api = {
        report: this.reportApi,
        data: this.dataModel
      }

      if (this.dataModel.report_option == 1) {
        this.reportTitle = "ACTIVE CLIENTS REPORT"
        this.reportColumns = [...this.reportColumnsActive]
      } else {
        this.reportTitle = "CLOSED CLIENTS REPORT"
        this.reportColumns = [...this.reportColumnsClose]
      }


      this.openDataInDialogToPrint()
      $store.dispatch('Accounting-Report-api/controlReports', api).then(r => {
        this.reportData = r
        this.openDataInDialogToPrint()
      })
    },
    openDataInDialogToPrint() {
      this.dialogVisible = true
    },
    getAccountingTypeFromId(id) {
      let o = this.dropdownAccountingOptions;
      let l = o.length
      for (let i = 0; i < l; i = i + 1) if (o[i].value == id) return o[i].label
      return ''
    },
    getGstHstTvqReportingType(id) {
      let o = this.dropdownAccountingOptions;
      let l = o.length
      for (let i = 0; i < l; i = i + 1) if (o[i].value == id) return o[i].label
      return ''
    },
  },
  beforeMount() {
    this.dataModel['province_of_operation_id'] = this.province_of_operation_id
    this.reportColumnsActive = [
      ...$services['Accounting-Report'].getReportColumns(['business_name', 'contact_name', 'full_work_phone', 'account_client_since', 'account_direct_deposit_fees'])
    ]

    this.reportColumnsClose = [
      ...$services['Accounting-Report'].getReportColumns(['business_name', 'contact_name', 'full_work_phone', 'account_date_of_close'])
    ]
  },
  data() {
    return {
      amReady: true,
      reportDateType: 'currentDay',
      reportApi: 'controlReportStatus',
      reportUiName: 'Account Status: ',
      reportTitle: "ACCOUNT STATUS REPORT",
      dropdownAccountStatus: jsonAccountStatus,
      withHighPriority: false,
      accountingTypeLabel: '',
      dropdownAccountingOptions: jsonAccountReportingPeriods,
      dueDateLabel: '',
      printPageWidth: 3508,
      printPageMargin: 10,
      reportData: {},
      reportColumns: [],
      reportColumnsActive: [],
      reportColumnsClose: [],
      dialogVisible: false,
      control: this,
      TaxFilingTypeDropdown: TaxFilingTypeJson,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
      dropdown12Month: json12month,
      taxReportingType: null,
    }
  }
}
</script>

<style scoped>
.table-for-printing tr:nth-child(even) td div {
  -webkit-print-color-adjust: exact;
  background-color: #c8c8c8;
}

.table-for-printing tbody:not(:nth-child(2)) tr:first-child {
  height: 50px;
  vertical-align: bottom
}

.table-for-printing tr:nth-child(odd) td div {
  border: 2px solid #000 !important;
  border-radius: 8px;
}

.table-for-printing-with-high-priority tr:nth-child(even) td div {
  -webkit-print-color-adjust: exact;
  background-color: #f1d03b;
  color: #000;
}

.table-for-printing-with-high-priority tr:nth-child(odd) td div {
  border: 2px solid #f1d03b !important;
  border-radius: 8px;
}
</style>
