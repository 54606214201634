<template>
  <div>
    <direct-deposit-report :province_of_operation_id="province_of_operation_id"></direct-deposit-report>
    <related-and-partner-report :province_of_operation_id="province_of_operation_id"></related-and-partner-report>
    <business-type-report :province_of_operation_id="province_of_operation_id"></business-type-report>
    <storage-unit-report :province_of_operation_id="province_of_operation_id"></storage-unit-report>
    <accounting-type-report :province_of_operation_id="province_of_operation_id"></accounting-type-report>
    <t4-rl1-report :province_of_operation_id="province_of_operation_id"></t4-rl1-report>
    <account-status-report :province_of_operation_id="province_of_operation_id"></account-status-report>
    <bookkeeper-report :province_of_operation_id="province_of_operation_id"></bookkeeper-report>
    <audit-report :province_of_operation_id="province_of_operation_id"></audit-report>
    <t2-year-end :province_of_operation_id="province_of_operation_id"></t2-year-end>
    <t4-a-or-t5 :province_of_operation_id="province_of_operation_id"></t4-a-or-t5>
  </div>
</template>

<script>
import DirectDepositReport from "@/modules/Accounting/Report/form/control-report/canada-tab/DirectDepositReport";
import T4Rl1Report from "@/modules/Accounting/Report/form/control-report/canada-tab/T4Rl1Report";
import RelatedAndPartnerReport
  from "@/modules/Accounting/Report/form/control-report/canada-tab/RelatedAndPartnerReport";
import StorageUnitReport from "@/modules/Accounting/Report/form/control-report/canada-tab/StorageUnitReport";
import BookkeeperReport from "@/modules/Accounting/Report/form/control-report/canada-tab/BookkeeperReport";
import BusinessTypeReport from "@/modules/Accounting/Report/form/control-report/canada-tab/BusinessTypeReport";
import AccountingTypeReport from "@/modules/Accounting/Report/form/control-report/canada-tab/AccountingTypeReport";
import AccountStatusReport from "@/modules/Accounting/Report/form/control-report/canada-tab/AccountStatusReport";
import AuditReport from "@/modules/Accounting/Report/form/control-report/canada-tab/AuditReport";
import T2YearEnd from "@/modules/Accounting/Report/form/control-report/canada-tab/T2YearEnd";
import T4AOrT5 from "@/modules/Accounting/Report/form/control-report/canada-tab/T4AOrT5";
export default {
  props: {
    control: {
      required: true
    }
  },
  data () {
    return {
      province_of_operation_id: '-all-'
    }
  },
  components: {
    T4AOrT5,
    T2YearEnd,
    AuditReport,
    AccountStatusReport,
    AccountingTypeReport,
    BusinessTypeReport,
    BookkeeperReport,
    StorageUnitReport,
    RelatedAndPartnerReport,
    T4Rl1Report,
    DirectDepositReport,
  },
}
</script>
